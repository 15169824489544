import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

import { matrixBackgroundStyle } from "utils"

const PartnersBadges = ({ title, subtitle, backgroundImage, media }) => {
  return (
    <StyledPartnersBadges>
      <VSection
        title={title}
        subtitle={subtitle}
        subtitleAs="pSmall"
        media={media}
        backgroundImage={backgroundImage}
      />
    </StyledPartnersBadges>
  )
}

const StyledPartnersBadges = styled.div`
  --section-align: center;
  --tg-text-align: center;
  --tg-max-width: 590px;

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }

  .v_mediaContainer {
    --v-section-media-top: var(--sp-32);
  }
`

export default PartnersBadges
