import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import {
  useContentfulSection,
  useMeta,
  useContentfulComponent,
  device,
} from "utils"
import { useLocation } from "@reach/router"
import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import BecomeAPartnerForm from "src/components/forms/BecomeAPartnerForm"

import {
  BecomePartnersHero,
  BecomePartnersDetails,
  BecomePartnersBadges,
} from "src/sections/becomePartner"

const BecomePartnerPage = ({ data: { become_partner } }) => {
  const { search } = useLocation()

  const meta = useMeta(become_partner)
  const {
    become_partner_hero,
    become_partner_details,
    become_partner_badges,
    become_partner_form,
  } = useContentfulSection(become_partner.sections)

  const { become_a_partner_form_url } = useContentfulComponent(
    become_partner_form.components
  )

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <BecomePartnersHero {...become_partner_hero} />
      <BecomePartnersDetails {...become_partner_details} />
      <BecomePartnersBadges {...become_partner_badges} />

      <BecomePartnerFormWrap>
        <BecomeAPartnerForm
          {...become_partner_form}
          url={become_a_partner_form_url.url}
        />
      </BecomePartnerFormWrap>
    </Layout>
  )
}

const BecomePartnerFormWrap = styled.div`
  padding-top: var(--section-mobile-padding);
  @media ${device.laptop} {
    padding-top: var(--section-padding);
  }
`

export const query = graphql`
  {
    become_partner: contentfulPage(pageId: { eq: "become_partner" }) {
      ...Page
    }
  }
`

export default BecomePartnerPage
