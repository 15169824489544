import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid, BasicCard } from "components/common"

import { device } from "utils"

const PartnersDetails = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => <ValueProp className={vp.vpId} {...vp} />}
    />
  )

  return (
    <StyledPartnersDetails>
      <VSection custom={() => <BasicCard>{renderGrid()}</BasicCard>} />
    </StyledPartnersDetails>
  )
}

const StyledPartnersDetails = styled.div`
  --section-padding-top: var(--sp-40);
  --section-mobile-padding-top: var(--sp-40);
  .v_customContainer {
    --grid-gap: var(--sp-16);
    --grid-col-max: 1fr;
    --grid-col: 1;
    --card-padding: var(--sp-24) var(--sp-24) var(--sp-24) var(--sp-48);

    @media ${device.laptop} {
      --card-padding: var(--sp-80);
    }

    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }

    .subtitle {
      white-space: break-spaces;
    }
  }
`

export default PartnersDetails
