import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

import { matrixBackgroundStyle, device } from "utils"

const PartnersHero = ({ title, subtitle, backgroundImage }) => {
  return (
    <StyledPartnersHero>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        backgroundImage={backgroundImage}
        critical
      />
    </StyledPartnersHero>
  )
}

const StyledPartnersHero = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    --tg-max-width: 472px;
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
    top: 70px;
    @media ${device.laptop} {
      top: calc(40px + 50%);
      transform: translateY(-50%);
    }
  }
`

export default PartnersHero
